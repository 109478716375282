export const TYPE_ACTIVE = 0
export const TYPE_PASSIVE = 1
export const TYPE_ACTIVE_NAME = 'active'
export const TYPE_PASSIVE_NAME = 'passive'
export const POINTS_PER_LEVEL = 2
export const FREE_POINTS = 1
export const STARTING_LEVEL = 1
export const MAX_LEVEL = 10
export const MAX_POINTS = POINTS_PER_LEVEL * (MAX_LEVEL - STARTING_LEVEL)

export const STATE_AVAILABLE = 0
export const STATE_LOCKED = 1
export const STATE_OBTAINED = 2
export const STATE_DISABLED = 3
export const STATE_OBTAINED_NAME = 'obtained'
export const STATE_AVAILABLE_NAME = 'available'
export const STATE_LOCKED_NAME = 'locked'
export const STATE_DISABLED_NAME = 'disabled'

export const TYPE_NAMES = {
    [TYPE_ACTIVE]: TYPE_ACTIVE_NAME,
    [TYPE_PASSIVE]: TYPE_PASSIVE_NAME
}

export const STATE_NAMES = {
    [STATE_AVAILABLE]: STATE_AVAILABLE_NAME,
    [STATE_LOCKED]: STATE_LOCKED_NAME,
    [STATE_OBTAINED]: STATE_OBTAINED_NAME,
    [STATE_DISABLED]: STATE_DISABLED_NAME
}
