/* eslint-disable react/display-name */
import React, { useContext, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useTranslation } from 'react-i18next'
import Button from 'tcweb-material-components/core/button'
import count from 'tcweb-material-components/core/poly/count'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

import { POINTS_PER_LEVEL, MAX_POINTS, FREE_POINTS } from './skillKeys'
import ClassContext from '../context/classContext'
import SkillGrid from './skillGrid'
import SkillList from './skillList'
import SkillPopper from './skillPopper'

function TransitionDown(props) {
    return <Slide {...props} direction='down' />
}

const useStyles = makeStyles(({ spacing, breakpoints, palette, gutters, accessibility: { highContrast } }) => ({
    buttonControlsContainer: {
        display: 'grid',
        gridRowGap: '50px',
        gridColumnGap: '24px',
        gridTemplateColumns: '1fr 1fr',
        marginBottom: '30px',
        [breakpoints.up('md')]: {
            marginBottom: '50px'
        }
    },
    resetButton: {
        justifySelf: 'right',
        width: 'auto',
        [breakpoints.down('xs')]: { width: '100%' },
        [highContrast.mediaQuery('active')]: {
            '&:hover, &:focus': {
                color: 'Highlight !important',
                textDecoration: 'underline 2px'
            }
        }
    },
    shareButton: {
        justifySelf: 'left',
        width: 'auto',
        [breakpoints.down('xs')]: { width: '100%' },
        [highContrast.mediaQuery('active')]: {
            color: 'ButtonText !important',
            '&:hover, &:focus': {
                textDecoration: 'underline 2px',
                color: 'Highlight !important'
            }
        }
    },
    snackbar: {
        color: palette.tertiary.contrastText,
        backgroundColor: '#3F6DAA',
        justifyContent: 'center'
    },
    boxHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: palette.background.primary.main,
        borderTop: `2px solid ${palette.border.primary}`,
        flexWrap: 'wrap',
        [breakpoints.down('sm')]: { flex: 1 },
        [breakpoints.up('md')]: {
            borderLeft: `2px solid ${palette.border.primary}`,
            borderRight: `2px solid ${palette.border.primary}`
        }
    },
    skillboxHeader: { padding: `25.5px 20px` },
    boxBody: {
        borderTop: `2px solid ${palette.border.primary}`,
        borderBottom: `2px solid ${palette.border.primary}`,
        padding: '20px 0',
        [breakpoints.up('md')]: {
            padding: '20px',
            overflowY: 'auto',
            flex: '1 0 0',
            borderLeft: `2px solid ${palette.border.primary}`,
            borderRight: `2px solid ${palette.border.primary}`
        }
    },
    skillsRoot: {
        [breakpoints.down('sm')]: {
            width: `calc(100% + (${gutters.page.sm} * 2))`,
            marginLeft: `-${gutters.page.sm}`
        },
        [breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: `${spacing(3)}px`,
            gridRowGap: `50px`
        }
    },
    skillsContainer: {
        [breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    skillTree: {
        [breakpoints.down('md')]: {
            marginBottom: '30px'
        }
    },
    skillsGrid: {
        padding: 0,
        overflow: 'visible'
    },
    boxTitle: {
        alignSelf: 'center',
        flexWrap: 'nowrap',
        marginRight: `${spacing(2)}px`,
        [breakpoints.up('md')]: {
            marginRight: `${spacing(3)}px`
        }
    },
    headerBodyContainer: {
        flex: 1,
        [breakpoints.down('sm')]: {
            fontSize: '17px'
        },
        [breakpoints.up('md')]: {
            display: 'flex',
            flexWrap: 'wrap'
        }
    },
    metaDataContainer: {
        display: 'flex',
        [breakpoints.down('sm')]: {
            flex: 1,
            flexWrap: 'wrap'
        }
    },
    metaDataItem: {
        marginRight: 20,
        [breakpoints.up('md')]: {
            marginRight: `${spacing(3)}px`
        }
    },
    metaDataTitle: {
        color: palette.text.primary,
        marginRight: `${spacing(0.5)}px`,
        [breakpoints.up('md')]: {
            marginRight: `${spacing(3)}px`
        }
    },
    metaDataItemLast: { marginRight: 0 },
    tooltipBtn: {
        width: 'auto',
        '&:hover, &:focus': { textDecoration: 'none' },
        [breakpoints.down('sm')]: { alignSelf: 'flex-start' },
        [highContrast.mediaQuery('active')]: {
            color: 'ButtonText !important',
            '&:hover, &:focus': {
                color: 'Highlight !important',
            }
        }
    },
    tooltip: {
        borderRadius: '100%'
    },
    mobileContainer: { paddingRight: 20 }
}))

export default () => {
    const _classes = useStyles()
    const { resetSkillTree, obtainedSkills, initialBuildCodeValid } = useContext(ClassContext)
    const { t } = useTranslation()
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const [isCopySnackOpen, setCopySnackOpen] = useState(false)
    const [isResetSnackOpen, setResetSnackOpen] = useState(false)
    const [isInvalidSnackOpen, setInvalidSnackOpen] = useState(!initialBuildCodeValid)

    const handleOnReset = () => {
        resetSkillTree()
        !!window && !!window.appInsights && window.appInsights.trackEvent({ name: 'Skills: Reset' })
        setResetSnackOpen(true)
    }

    const handleOnShare = (e) => {
        const copyInput = document.createElement('input')
        document.body.appendChild(copyInput)
        copyInput.value = window.location.href
        copyInput.select()
        document.execCommand('copy')
        document.body.removeChild(copyInput)
        !!window && !!window.appInsights &&
            window.appInsights.trackEvent({
                name: 'Skills: Share',
                properties: {
                    buildUrl: window.location.href
                }
            })
        setCopySnackOpen(true)
        e.currentTarget.focus()
    }

    const handleOnInvalidBuild = () => {
        !!window && !!window.appInsights && window.appInsights.trackEvent({ name: 'Skills: Invalid Build' })
        setInvalidSnackOpen(false)
    }

    const pointsSpent = count(obtainedSkills) - FREE_POINTS
    const requiredLevel = Math.ceil(pointsSpent / POINTS_PER_LEVEL) + 1
    const pointsRemaining = MAX_POINTS - pointsSpent

    const onTooltipShow = useCallback(() => setIsTooltipOpen(true))
    const onTooltipHide = useCallback(() => setIsTooltipOpen(false))

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                ContentProps={{
                    classes: { root: _classes.snackbar }
                }}
                open={isInvalidSnackOpen}
                autoHideDuration={6000}
                onClose={handleOnInvalidBuild}
                message={t('Invalid build, Skill Tree was reset!')}
                TransitionComponent={TransitionDown}
            />
            <div className={_classes.buttonControlsContainer}>
                <Button
                    variant='contained'
                    color='secondary'
                    classes={{ root: _classes.resetButton }}
                    onClick={handleOnReset}
                >
                    {t('Reset Skills')}
                </Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    ContentProps={{
                        classes: { root: _classes.snackbar }
                    }}
                    open={isResetSnackOpen}
                    autoHideDuration={6000}
                    onClose={() => setResetSnackOpen(false)}
                    message={t('Skill Tree was reset!')}
                    TransitionComponent={TransitionDown}
                />
                <Button
                    variant='contained'
                    color='secondary'
                    classes={{ root: _classes.shareButton }}
                    onClick={handleOnShare}
                    startIcon={<FontAwesomeIcon icon='link' />}
                >
                    {t('Copy Link')}
                </Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    ContentProps={{
                        classes: { root: _classes.snackbar }
                    }}
                    open={isCopySnackOpen}
                    autoHideDuration={6000}
                    onClose={() => setCopySnackOpen(false)}
                    message={t('Link copied to your clipboard!')}
                    TransitionComponent={TransitionDown}
                />
            </div>

            <div className={_classes.skillsRoot}>
                <div className={_classes.skillTree}>
                    <div className={_classes.boxHeader}>
                        <div className={_classes.headerBodyContainer}>
                            <Typography className={classnames(_classes.boxTitle, 'h4')} variant='h2'>
                                {t('Skill Tree')}
                            </Typography>
                            <div className={_classes.metaDataContainer}>
                                <div className={classnames(_classes.metaDataItem)}>
                                    <p className='sr-only' aria-live='polite'>
                                        {t('slug:sr-only-level-required', { level: requiredLevel })}
                                    </p>
                                    <span className={classnames(_classes.metaDataTitle, 'h4')}>
                                        {t('Level')}:
                                    </span>
                                    <span className='h4'>
                                        {requiredLevel}
                                    </span>
                                </div>
                                <div className={_classes.metaDataItem}>
                                    <p className='sr-only' aria-live='polite'>
                                        {t('slug:sr-only-points-remaining', { points: pointsRemaining })}
                                    </p>
                                    <span className={classnames(_classes.metaDataTitle, 'h4')}>
                                        {t('Points available')}:
                                    </span>
                                    <span className='h4'>
                                        {pointsRemaining}
                                    </span>
                                </div>
                                <div className={classnames(_classes.metaDataItem, _classes.metaDataItemLast)}>
                                    <p className='sr-only' aria-live='polite'>
                                        {t('slug:sr-only-points-spent', { points: pointsSpent })}
                                    </p>
                                    <span className={classnames(_classes.metaDataTitle, 'h4')}>
                                        {t('Spent')}:
                                    </span>
                                    <span className='h4'>
                                        {pointsSpent}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <SkillPopper
                            open={isTooltipOpen}
                            isFlipped
                            onClose={onTooltipHide}
                            onOpen={onTooltipShow}
                            mobileContainerClassName={_classes.mobileContainer}
                            title={<Typography>{t('Choose a skill to add. Click or tap on the skill again to undo.')}</Typography>}
                        >
                            <Button
                                className={_classes.tooltipBtn}
                                onClick={onTooltipShow}
                                aria-expanded={String(isTooltipOpen)}
                                aria-haspopup={'true'}
                                aria-label={t('Learn more about the Skill Planner')}
                            >
                                {/* <Zoom in style={{ transitionDelay: '500ms', animation: 'shadow-pulse 1s 0.5s 2' }}> */}
                                <InfoOutlinedIcon className={_classes.tooltip} />
                                {/* </Zoom> */}
                            </Button>
                        </SkillPopper>
                    </div>
                    <div className={classnames('bg-gradient', _classes.boxBody, _classes.skillsGrid)}>
                        <SkillGrid isDisabled={pointsRemaining === 0} />
                    </div>
                </div>

                <div className={_classes.skillsContainer}>
                    <div className={classnames(_classes.boxHeader, _classes.skillboxHeader)}>
                        <Typography className='h4' variant='h2'>
                            {t('Skill List')}
                        </Typography>
                    </div>
                    <div className={classnames('bg-gradient', _classes.boxBody)}>
                        <SkillList />
                    </div>
                </div>
            </div>
        </>
    )
}
