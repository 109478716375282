import React, { useState, useContext, useCallback, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import range from 'lodash/range'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import Button from 'tcweb-material-components/core/button'
import classnames from 'classnames'
import camelCase from 'tcweb-material-components/core/poly/camelCase'

import { TYPE_ACTIVE, TYPE_PASSIVE, STATE_NAMES, STATE_AVAILABLE, STATE_OBTAINED, STATE_LOCKED } from './skillKeys'
import ClassContext from '../context/classContext'
import SkillIcon from './skillIcon'
import SkillDetails from './skillDetails'

const DESKTOP_ICON_SIZE = 32
const MOBILE_ICON_SIZE = 26

const shapeBaseStyles = {
    position: 'absolute',
    zIndex: -1,
    strokeWidth: '2px',
    overflow: 'visible'
}

const shapeHoverStyles = (palette) => ({
    stroke: palette.button.outline.quintary.hover.borderColor,
})

const shapeLocked = (palette) => ({
    fill: palette.background.default,
    stroke: palette.button.outline.quintary.borderColor,
    ...shapeBaseStyles
})

const shapeAvailable = (palette) => ({
    fill: palette.border.secondary,
    stroke: palette.secondary.light,
    ...shapeBaseStyles
})

const shapeObtained = (palette) => ({
    fill: palette.primary.light,
    stroke: palette.border.contrast,
    ...shapeBaseStyles,
})

const useClasses = makeStyles(({ palette, breakpoints }) => ({
    root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        '&:hover': {
            '& span > svg': {
                ...shapeHoverStyles(palette)
            }
        }
    },
    rootFocus: {
        '& span > svg': {
            ...shapeHoverStyles(palette)
        }
    },
    power: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        right: '15%',
        fontSize: '0.9em',
        '& svg': {
            transform: 'scale(1.5, 1.35)',
            stroke: palette.common.black,
            strokeWidth: '30px',
            '@media (-ms-high-contrast: active), (forced-colors: active)': {
                'forced-color-adjust': 'none',
                stroke: `windowText`,
            }
        },
        '& svg:not(:first-child)': {
            marginTop: '-6.5px'
        },
        '&[data-power="1"]': {
            transform: 'translateY(-5px)',
        },
        '&[data-power="2"]': {
            transform: 'translateY(-8px)',
        },
        '&[data-power="3"]': {
            transform: 'translateY(-11px)',
        },
        '@media (-ms-high-contrast: active)': {
            opacity:'1 !important',
            padding: '0 2px',
            '& svg': {
                strokeWidth: '20px'
            }
        },
        '@media (-ms-high-contrast: black-on-white)': {
            backgroundColor: 'white !important',
        },
        '@media (-ms-high-contrast: white-on-black)': {
            backgroundColor: 'white !important',
        },
        [breakpoints.up('sm')]: {
            fontSize: '1em',
            '&::before': { top: '-6px' }
        }
    },

    labelBase: { zIndex: 2, color: '#FFF' },

    iconLocked: { opacity: 0.5 },

    hexagonLocked: { ...shapeLocked(palette) },
    hexagonAvailable: { ...shapeAvailable(palette) },
    hexagonObtained: { ...shapeObtained(palette) },

    squareLocked: { ...shapeLocked(palette) },
    squareAvailable: { ...shapeAvailable(palette) },
    squareObtained: {
        ...shapeObtained(palette)
    },
    contrastBorderLocked: { stopColor: shapeLocked(palette).fill },
    contrastBorderAvailable: { stopColor: shapeAvailable(palette).fill },
    contrastBorderObtained: { stopColor: shapeObtained(palette).fill },
    contrastBorderFadeLocked: { stopColor: fade(shapeLocked(palette).fill, 0) },
    contrastBorderFadeAvailable: { stopColor: fade(shapeAvailable(palette).fill, 0) },
    contrastBorderFadeObtained: { stopColor: fade(shapeObtained(palette).fill, 0) }
}))

const SkillNode = ({ index, skillId, state, isDisabled, tooltipFlipped }) => {
    const _classes = useClasses()
    const theme = useTheme()
    const { t } = useTranslation()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isHighContrast = useMediaQuery('(-ms-high-contrast: active)')
    const { currentClass, obtainSkill, deleteSkill } = useContext(ClassContext)
    const [detailsVisible, setDetailsVisible] = useState(false)
    const anchorRef = useRef({})
    const skill = currentClass.skills[skillId]
    const _state = useMemo(() => (isDisabled && state === STATE_AVAILABLE ? STATE_LOCKED : state), [isDisabled, state])

    const onDetailsShow = useCallback(() => setDetailsVisible(true))
    const onDetailsHide = useCallback(() => setDetailsVisible(false))

    const onClick = () => {
        onDetailsShow()

        switch (_state) {
            case STATE_AVAILABLE:
                obtainSkill(index)
                return
            case STATE_OBTAINED:
                deleteSkill(index)
                return
            default:
                return
        }
    }

    return (
        <SkillDetails skill={skill} open={detailsVisible} isFlipped={tooltipFlipped} onClose={onDetailsHide} onOpen={onDetailsShow}>
            <Button
                classes={{ root: _classes.root, label: _classes.labelBase, focusVisible: _classes.rootFocus }}
                onClick={onClick}
                ref={anchorRef}
                aria-expanded={String(detailsVisible)}
                aria-label={t('slug:state-skill-skill', {
                    skill: t(skill.displayName),
                    state: t(STATE_NAMES[_state])
                })}
            >
                {skill.type === TYPE_ACTIVE && (
                    <svg viewBox='0 0 56 63' className={[_classes[camelCase(['hexagon', STATE_NAMES[_state]])]]}>
                        <use xlinkHref='#shadowObtained' />
                        <use xlinkHref='#shadowHover' />
                        {skill.maxPower !== 1 && (
                            <linearGradient id={`gradient-${STATE_NAMES[_state]}-${index}`} x1='0%' y1='40%' x2='100%' y2='80%'>
                                <stop className={_classes[camelCase(['contrastBorderFade', STATE_NAMES[_state]])]} offset='0%' />
                                <stop className={_classes[camelCase(['contrastBorder', STATE_NAMES[_state]])]} offset='30%' />
                                <stop className={_classes[camelCase(['contrastBorder', STATE_NAMES[_state]])]} offset='70%' />
                                <stop className={_classes[camelCase(['contrastBorderFade', STATE_NAMES[_state]])]} offset='100%' />
                            </linearGradient>
                        )}
                        <polygon points='0.5 46.96 0.5 16.04 28 0.57 55.5 16.04 55.5 46.96 28 62.43 0.5 46.96'
                        />
                        <line
                            x1='29.14'
                            y1='1.22'
                            x2='53.98'
                            y2='15.2'
                            style={{ strokeWidth: 2.4, stroke: `url(#gradient-${STATE_NAMES[_state]}-${index})` }}
                        />
                    </svg>
                )}
                {skill.type === TYPE_PASSIVE && (
                    <svg viewBox='0 0 56 56' className={[_classes[camelCase(['square', STATE_NAMES[_state]])]]}>
                        <rect width='56' height='56' />
                    </svg>
                )}
                {(!!skill.power && skill.maxPower !== 1) && (
                    <div className={classnames(_classes.power, _classes[camelCase(['power', STATE_NAMES[_state]])])} data-power={skill.power}>
                        {range(0, skill.power).map(i => <FontAwesomeIcon key={`${skill.name}-${i}`} icon={['fa', 'chevron-up']} size='xs' />)}
                    </div>
                )}
                <SkillIcon
                    className={classnames({ [_classes.iconLocked]: _state === STATE_LOCKED })}
                    icon={skill.icon}
                    size={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
                />
            </Button>
        </SkillDetails>
    )
}

export default SkillNode
