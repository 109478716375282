import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Button from 'tcweb-material-components/core/button'
import classnames from 'classnames'

const ESC_KEY = 27

const useClasses = makeStyles(({ palette, navigation, breakpoints }) => ({
    root: {
        fontSize: 'inherit',
        padding: '10px',
        textAlign: 'left',
        backgroundColor: palette.secondary.main,
        border: `1px solid ${palette.border.primary}`,
        filter: `drop-shadow(0 0 10px ${palette.common.black})`,
        [breakpoints.up('md')]: { maxWidth: 'none', width: 'auto' }
    },
    mobileContainer: {
        overflowY: 'auto',
        width: '100%',
        maxHeight: `calc(100vh - ${navigation.height})`,
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: 100,
        [breakpoints.only('sm')]: {
            bottom: navigation.tablet.height,
            maxHeight: `calc(100vh - ${navigation.tablet.height})`,
        }
    },
    arrow: ({ isFlipped }) => ({
        display: 'block',
        backgroundColor: palette.secondary.main,
        transform: `rotate(45deg) translate(${isFlipped ? '-5px, -5px' : '5px, 5px'})`,
        width: '10px !important',
        height: '10px !important',
        borderTop: !isFlipped && `1px solid ${palette.border.primary}`,
        borderLeft: !isFlipped && `1px solid ${palette.border.primary}`,
        borderBottom: isFlipped && `1px solid ${palette.border.primary}`,
        borderRight: isFlipped && `1px solid ${palette.border.primary}`,
        '&::before': { display: 'none' }
    }),
    closeButton: {
        position: 'absolute',
        width: 'auto',
        right: 10,
        top: 15,
        zIndex: 101
    }
}))

const SkillPopper = ({ children, isFlipped, open, onClose, mobileContainerClassName, title, ...rest }) => {
    const [bottom, setBottom] = useState(0)
    const _classes = useClasses({ isFlipped })
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { t } = useTranslation()
    const closeButtonRef = useRef({})

    useEffect(() => {
        if (isMobile && open) {
            const bottomNav = document.querySelector('[class*="MuiBottomNavigation-root"]')

            if (bottomNav) {
                setBottom(bottomNav.offsetHeight)
            }

            closeButtonRef.current.focus()
        } else {
            setBottom(0)
        }
    }, [open])

    useEffect(() => {
        if (!isMobile) {
            const handleEsc = event => {
                if (event.keyCode === ESC_KEY) {
                    onClose()
                }
            }

            window.addEventListener('keydown', handleEsc)

            return () => {
                window.removeEventListener('keydown', handleEsc)
            }
        }
    }, [isMobile])

    if (isMobile) {
        return (
            <>
                {children}
                {open &&
                    <ClickAwayListener onClickAway={onClose}>
                        <div className={_classes.mobileContainer} style={{ bottom }}>
                            <Button
                                className={_classes.closeButton}
                                onClick={onClose}
                                aria-label={t('Close')}
                                ref={closeButtonRef}
                            >
                                <FontAwesomeIcon icon={['tcs', 'close']} size='1x' />
                            </Button>
                            <div className={classnames(mobileContainerClassName, _classes.root)}>
                                {title}
                            </div>
                        </div>
                    </ClickAwayListener>
                }
            </>
        )
    }

    return (
        <Tooltip
            arrow
            interactive
            open={open}
            classes={{
                popper: _classes.popper,
                tooltip: _classes.root,
                arrow: _classes.arrow
            }}
            PopperProps={{
                disablePortal: true,
                modifiers: { flip: { enabled: false } }
            }}
            placement={isFlipped ? 'top' : 'bottom'}
            onClose={onClose}
            title={<div aria-live="polite">{title}</div>}
            enterDelay={100}
            enterNextDelay={200}
            {...rest}
        >
            {children}
        </Tooltip>
    )
}

export default SkillPopper
