import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import map from 'tcweb-material-components/core/poly/map'
import reduce from 'lodash/reduce'
import remove from 'lodash/remove'

import { formatSkillTranslation } from '../../utilities/helpers'
import { TYPE_ACTIVE } from './skillKeys'
import ClassContext from '../context/classContext'
import SkillIcon from './skillIcon'

const ICON_SIZE  = 50

const useClasses = makeStyles(({ spacing, palette, breakpoints, accessibility }) => ({
    '@keyframes glow': {

    },
    listRoot: {
      display: 'grid',
      gridRowGap: '10px'
    },
    root: {
      display: 'grid',
      padding: '20px',
      backgroundColor: palette.background.default,
      gridColumnGap: `${spacing(3)}px`,
      gridTemplateColumns: `${ICON_SIZE}px 1fr`,
      border: `2px solid ${palette.border.primary}`,
   },
   rootInteractive:{ // Moved Hover to unused class for future of collapse / expand list items
    '&:hover': {
      position: 'relative',

      '&::after': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: `1px solid ${palette.border.contrast}`,
          boxShadow: `0 0px 10px ${palette.border.primary}`,
          color: palette.secondary.contrastText,
          zIndex: 1
      },
    }
   },
   icon: {
     width:'100% !important', // Overwrite Fontawesome width
     height: 'auto'
   },
   subTitle: { margin: 0 },
   description: { margin: 0, color: palette.text.primary },
   prevSkillItemContainer: {
     "& > ": {
      '&::before' : {
        content: "''",
        top: 0,
        bottom: 0,
        position: 'absolute',
        transform: 'translateX(-20px)',
        borderLeft: `2px solid ${palette.secondary.border}`
      },
      "& :last-child::before":{
        height: '16px'
      }
     }
   },
   prevSkillItem: {
      paddingLeft: `${spacing(3)-2}px`,
      position: 'relative',
      [breakpoints.up('sm')]:{ display: 'flex', flexDirection: 'row' }
    },
   prevSkillItemHeader: {
     whiteSpace: 'nowrap',
     marginRight: spacing(2),
     position: 'relative',
     '&::before' : {
        content: "''",
        top: 0,
        bottom: 0,
        width: spacing(2),
        position: 'absolute',
        transform: `translate(-20px, 14px);`,
        borderTop: `2px solid ${palette.secondary.border}`
      },
    },
   prevSkillItemBody: { color: palette.text.primary },
   icon: {
    ...accessibility.highContrast.blackOnWhite
   }
}))

const getObjList = () => {
  const { obtainedSkills } = useContext(ClassContext)
  const { currentClass } = useContext(ClassContext)
  const children = {}
  return reduce(obtainedSkills, (result, id) => {
    let skill = currentClass.skills[id]
      if(!children[id]){
        if(skill.previousSkill !== null){
          let getChildren = (child) => {
            if(child.previousSkill === null) {
              children[child.id] = child
              return child
            }
            children[child.id] = child
            return { ...child, previousSkill: getChildren(currentClass.skills[child.previousSkill]) }
          }
          remove(result, r => r.id === skill.previousSkill)
          result.push(getChildren(skill))
        }else{
          result.push(skill)
        }
      }
    return result
  }, [])
}

const PrevSkillItem = ({ _classes, t, displayName, description, previousSkill, values }) => {
  return (
    <>
      <div className={_classes.prevSkillItem}>
        <Typography className={_classes.prevSkillItemHeader}>{t(displayName)}</Typography>
        <Typography className={_classes.prevSkillItemBody}>{formatSkillTranslation(t(description), ...values)}</Typography>
      </div>
      {!!previousSkill &&
        <PrevSkillItem _classes={_classes} t={t} {...previousSkill} />
      }
    </>
  )
}

const SkillListItem = ({ icon, displayName, type, description, values, isFirst, previousSkill }) => {
  const { t } = useTranslation()
  const _classes = useClasses()

  return (
      <div className={_classes.root} role='listitem' aria-label={!!isFirst ? t('Most recent added skill'): t('skill') }>
        <SkillIcon icon={icon} size={ICON_SIZE} className={_classes.icon} />
        <div>
          <Typography className='h4' variant='h3'>
            {t(displayName)}
          </Typography>
          <Typography className={_classes.subTitle}>
            {type === TYPE_ACTIVE ? t('Active Skill') : t('Passive Skill')}
          </Typography>
          <Typography className={_classes.description}>{formatSkillTranslation(t(description), ...values)}</Typography>
          {!!previousSkill &&
            <div className={_classes.prevSkillItemContainer}>
              <PrevSkillItem _classes={_classes} t={t} {...previousSkill} />
            </div>
          }
        </div>
      </div>
  )
}

export default () => {
  const _classes = useClasses()
  const objSkills = getObjList()

  return (
    <div className={_classes.listRoot} role='list'>
      {map((objSkill, i) => <SkillListItem key={i} isFirst={i===0} {...objSkill} />, objSkills)}
    </div>
  )
}
