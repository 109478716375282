import React, { useContext } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import map from 'tcweb-material-components/core/poly/map'
import classnames from 'classnames'
import reduce from 'lodash/reduce'

import ClassContext from '../context/classContext'
import SkillNode from './skillNode'
import SkillQuadrant from './skillQuadrant'
import { STATE_AVAILABLE, STATE_OBTAINED } from './skillKeys'

const GRID_START_OFFSET = 1
const GRID_END_OFFSET = 2
const MOBILE_GRID_GAP = 10
const DESKTOP_GRID_GAP = 20
const NODE_SIZE_DESKTOP = 56
const NODE_SIZE_MOBILE = 45

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    overflow: 'visible',
    '@media (min-width: 321px)': { justifyContent: 'center' },
    '@media (max-width: 320px)': { overflowX: 'auto' }
  },
  skillTreeContainer: {
    display: 'grid',
    gridTemplateAreas: `
      "quadrant1 quadrant2"
      "skillTree skillTree"
      "quadrant3 quadrant4"
    `,
    justifyContent: 'start',
    alignItems: 'center',
    padding: 10,
    [breakpoints.up('sm')]: { justifyContent: 'center', padding: 20 }
  },
  skillGrid: {
    position: 'relative',
    gridArea: 'skillTree',
    display: 'grid',
    margin: '20px 0',
    gridTemplateColumns: ({ numColumns }) => `repeat(${numColumns}, ${NODE_SIZE_MOBILE}px)`,
    gridAutoRows: `${NODE_SIZE_MOBILE}px`,
    gridGap: MOBILE_GRID_GAP,
    backgroundSize: '100% 44%',
    backgroundRepeat: 'no-repeat',
    '&::before' : {
      content: "''",
      width: 0,
      paddingBottom: '100%',
      gridRow: '1/1',
      gridColumn: '1/1'
    },
    '& > *:first-child': {
      gridRow: '1/1',
      gridColumn: '1/1'
    },
    '&::after': {
      content: '" "',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundSize: '100% 44%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom'
    },
    '@media (min-width: 321px)': {
      gridTemplateColumns: ({ numColumns }) => `repeat(${numColumns}, calc((100vw / 8) - 5px))`,
      gridAutoRows: 'calc((100vw / 8) - 5px)',
    },
    [breakpoints.up('sm')]: {
      gridGap: DESKTOP_GRID_GAP,
      gridTemplateColumns: ({ numColumns }) => `repeat(${numColumns}, ${NODE_SIZE_DESKTOP}px)`,
      gridAutoRows: `${NODE_SIZE_DESKTOP}px`,
    },

  },
  skill: {
    position: 'relative',
    width: `100%`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  skillConnector: {
    position: 'absolute',
    content: '" "',
    borderTop: `2px dotted ${palette.secondary.light}`,
    borderLeft: `2px dotted ${palette.secondary.light}`,

    '@media (-ms-high-contrast: active), (forced-colors: active)': {
      'forced-color-adjust': 'none',
      borderTop: '2px dotted grayText',
      borderLeft: '2px dotted grayText',
    },
    zIndex: 1,
  },
  whiteConnector: {
    borderTop: `2px solid ${palette.border.contrast}`,
    borderLeft: `2px solid ${palette.border.contrast}`,
    '@media (-ms-high-contrast: active), (forced-colors: active)': {
      'forced-color-adjust': 'none',
      borderTop: '2px solid windowText',
      borderLeft: '2px solid windowText',
    }
  },
  verticalConnector: {
    height: MOBILE_GRID_GAP + 10,
    width: 1,
    [breakpoints.up('sm')]: {
      height: DESKTOP_GRID_GAP + 3
    }
  },
  horizontalConnector: {
    height: 1,
    width: MOBILE_GRID_GAP + 10,
    [breakpoints.up('sm')]: {
      width: DESKTOP_GRID_GAP + 3
    }
  }
}))

const getDirection = (parent, child) => {
  const { x: parentX, y: parentY } = parent.coords
  const { x: childX, y: childY } = child.coords

  if (parentX === childX) {
    if (parentY < childY) { // left or right
      return 'bottom'
    } else {
      return 'top'
    }
  }

  if (parentX < childX) {
    return 'right'
  }

  return 'left'
}

const getUnlockableDirections = (skillNode, currentClass) => reduce(skillNode.unlocks, (result, unlock) => {
    let unlockNode = currentClass.skillTree[unlock]
    if(unlockNode.state === STATE_OBTAINED) result.push(getDirection(skillNode, unlockNode))
    return result
}, [])

const SkillGrid = ({ isDisabled }) => {
  const { currentClass } = useContext(ClassContext)
  const _classes = useStyles({ numColumns: currentClass.skillTreeStructure.numColumns })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const flipThreshold = Math.ceil(currentClass.skillTreeStructure.numColumns / 2)

  return (
    <div className={_classes.root}>
      <div className={_classes.skillTreeContainer}>
        {map((quadrant, index) => (
          <div key={quadrant.name} style={{ gridArea: `quadrant${index + 1}` }}>
            <SkillQuadrant quadrant={quadrant} index={index} />
          </div>
        ), currentClass.quadrants)}
        <div className={_classes.skillGrid}>
          {map((skillNode, index) => (
            <div
              key={skillNode.skillId}
              className={_classes.skill}
              style={{
                gridColumnStart: skillNode.coords.x + GRID_START_OFFSET,
                gridColumnEnd: skillNode.coords.x + GRID_END_OFFSET,
                gridRowStart: skillNode.coords.y + GRID_START_OFFSET,
                gridRowEnd: skillNode.coords.y + GRID_END_OFFSET
              }}
            >
              {map(skillNodeId => {
                const unlockable = currentClass.skillTree[skillNodeId]
                const direction = getDirection(skillNode, unlockable)
                const unlockableDirections = getUnlockableDirections(skillNode, currentClass)

                return (
                  <div
                    key={skillNodeId}
                    className={classnames(
                      _classes.skillConnector,
                      {
                        [_classes.verticalConnector]: ['top', 'bottom'].includes(direction),
                        [_classes.horizontalConnector]: ['left', 'right'].includes(direction),
                        [_classes.whiteConnector]: isDisabled
                          ? skillNode.state === STATE_OBTAINED && unlockableDirections.includes(direction)
                          : (skillNode.state === STATE_OBTAINED) || (skillNode.state === STATE_AVAILABLE && unlockableDirections.includes(direction)),
                      }
                    )}
                    style={{
                      [direction]: `-${isMobile ? MOBILE_GRID_GAP + 5 : DESKTOP_GRID_GAP + 3}px`
                    }}
                  />
                )
              }, skillNode.unlocks)}
              <SkillNode index={index} state={skillNode.state} skillId={skillNode.skillId} isDisabled={isDisabled} tooltipFlipped={!(flipThreshold > skillNode.coords.y)} />
            </div>
          ), currentClass.skillTree)}
        </div>
      </div>
    </div>
  )
}

export default SkillGrid
