import { createContext } from 'react'

const ClassContext = createContext({
    classes: {},
    validateSkillTree: () => null,
    resetSkillTree: () => null,
    activateSkill: () => null
})

export default ClassContext
