import React, { useEffect, useReducer } from 'react'
import { graphql, navigate } from 'gatsby'
import sortBy from 'lodash/sortBy'
import pipe from 'lodash/fp/pipe'
import cloneDeep from 'lodash/cloneDeep'
import each from 'lodash/each'
import find from 'lodash/find'
import get from 'lodash/get'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import map from 'tcweb-material-components/core/poly/map'
import reduce from 'tcweb-material-components/core/poly/reduce'
import filter from 'tcweb-material-components/core/poly/filter'
import count from 'tcweb-material-components/core/poly/count'
import Section from 'tcweb-material-components/core/section'
import { encodeQueryData } from 'tcweb-material-components/core/utilities/helpers'
import Hero from 'tcweb-material-components/core/hero'
import HeroContent from 'tcweb-material-components/core/heroContent'
import Helmet from 'react-helmet'
import classnames from 'classnames'

import i18n from '../utilities/i18n'
import { getLocaleFromWPMLLocale } from '../utilities/locale'
import Link from 'gatsby-link'

import { STATE_LOCKED, STATE_AVAILABLE, STATE_OBTAINED } from '../components/classes/skillKeys'
import ClassContext from '../components/context/classContext'
import LayoutContent from '../components/layout/layoutContent'
import useLocation from '../hooks/useLocation'
import SkillBuilder from '../components/classes/skillBuilder'
import heroImgDesktop from '../assets/images/skills/hero-desktop.png'
import heroImgMobile from '../assets/images/skills/hero-mobile.jpg'

const Skeleton = loadable(() => import('@material-ui/lab/Skeleton'))
const Tab = loadable(() => import('tcweb-material-components/core/tab'))
const Tabs = loadable(() => import('tcweb-material-components/core/tabs'))

const useStyles = makeStyles(({ palette, breakpoints, spacing, accessibility: { highContrast } }) => ({
    section: {
        paddingTop: '30px',
        paddingBottom: '30px',
        [breakpoints.up('md')]: {
            paddingTop: '50px',
            paddingBottom: '100px'
        }
    },
    hero: {
        backgroundSize: 'cover',
        backgroundRepeat: ' no-repeat',
        backgroundPosition: 'center'
    },
    heroContent: { justifyContent: 'center' },
    heroHeader: { marginBottom: spacing(2) },
    heroContentContainer: {
        display: 'flex',
        maxWidth: '900px',
        textAlign: 'center',
        flexDirection: 'column',
        marginBottom: 0,
        color: palette.secondary.contrastText,
        [breakpoints.down('xs')]: { padding: '2rem 0' },
        [breakpoints.only('sm')]: { padding: '2rem' },
        [breakpoints.only('md')]: { padding: '4rem' }
    },
    heroContentDescription: {
        '& > * ': {
            display: 'inline',
            marginRight: spacing(0.75)
        }
    },
    tabPanel: { marginBottom: 0 },
    tab: {
        [highContrast.mediaQuery('active')]: {
            '&:hover, &:focus': {
                border: '1px solid Highlight',
                color: 'Highlight !important'
            }
        }
    }
}))

const usSkeletonStyles = makeStyles(({ spacing, breakpoints }) => ({
    skeletonTabsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [breakpoints.up('md')]: {
            width: '500px',
            alignSelf: 'center'
        }
    },
    skeletonSubLinks: {
        alignSelf: 'center'
    },
    skeletonSkillsDesktopContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: spacing(2)
    },
    skeletonSection: {
        paddingTop: '30px',
        paddingBottom: '30px',
        [breakpoints.up('md')]: {
            paddingTop: '50px',
            paddingBottom: '100px'
        }
    }
}))

const SkeletonUI = () => {
    const classes = usSkeletonStyles()
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

    return (
        <>
            <Skeleton variant='rect' height={'45vh'} />
            <Section className={classes.skeletonSection}>
                <div className={classes.skeletonTabsContainer}>
                    <Skeleton width='15%' variant='text' />
                    <Skeleton width='15%' variant='text' />
                    <Skeleton width='15%' variant='text' />
                    <Skeleton width='15%' variant='text' />
                </div>
                <Skeleton width='40%' variant='text' classes={{ root: classes.skeletonSubLinks }} />
                {isDesktop ? (
                    <div className={classes.skeletonSkillsDesktopContainer}>
                        <Skeleton variant='rect' height={'80vh'} />
                        <Skeleton variant='rect' height={'80vh'} />
                    </div>
                ) : (
                    <Skeleton variant='rect' height={'80vh'} />
                )}
            </Section>
        </>
    )
}

const SkillsHelmet = ({ data }) => {
    const { t } = useTranslation()
    const title = `${get(data, 'siteData.siteMetadata.title')} | ${t('Skills')}`
    const imgAlt = t('The Gears Tactics logo, below are three squad members standing with a skill tree behind them.')

    return (
        <Helmet
            title={title}
            meta={[
                // Twitter Card Data
                { name: 'twitter:title', content: title },
                !!get(data, 'socialImage.publicURL') &&
                    !!get(data, 'siteData.siteMetadata.siteUrl') && {
                        name: 'twitter:image',
                        content: `${data.siteData.siteMetadata.siteUrl}${data.socialImage.publicURL}`
                    },
                { name: 'twitter:image:alt', content: imgAlt },
                // Open Graph Data
                { property: 'og:title', content: title },
                { property: 'og:url', content: `${data.siteData.siteMetadata.siteUrl}/skills` },
                !!get(data, 'socialImage.publicURL') &&
                    !!get(data, 'siteData.siteMetadata.siteUrl') && {
                        property: 'og:image',
                        content: `${data.siteData.siteMetadata.siteUrl}${data.socialImage.publicURL}`
                    },
                { property: 'og:image:alt', content: imgAlt }
            ]}
        />
    )
}

const getCurrentClassName = (characterClass, characterClasses) => {
    if (!characterClass) return characterClasses[0] // TODO: Fix later (fails with LOC) - English only urls
    const categoryFromURl = find(characterClasses, (c) => c === characterClass)
    if (!categoryFromURl) {
        window.history.replaceState('', '', 'classes')
        return characterClasses[0] // TODO: Fix later (fails with LOC) - English only urls
    }
    return characterClass
}

const validateBuildCode = (buildCode) => {
    if (buildCode.length > 19) {
        // Too long
        return false
    }
    if (buildCode.match(/[^a-y0-9BFJPV]/)) {
        // Contains characters outside serializable range
        return false
    }
    if (buildCode.match(/[aeiouy]/)) {
        // Contains characters outside serializable range
        return false
    }
    if (/(.).*\1/.test(buildCode)) {
        // Contains duplicate characters
        return false
    }

    return true
}

const skillsToBuildCode = (skills) => {
    return skills.map((s) => removeVowel(s.toString(36))).join('')
}

const buildCodeToSkills = (buildCode) => {
    return buildCode.split('').map((c) => parseInt(addVowel(c), 36))
}

const removeVowel = (c) => {
    return /[aeiouy]/.test(c) ? String.fromCharCode(c.charCodeAt(0) + 1).toUpperCase() : c
}

const addVowel = (c) => {
    return /[BFJPVZ]/.test(c) ? String.fromCharCode(c.charCodeAt(0) - 1).toLowerCase() : c
}

const reducer = (state, action) => {
    const updateQueryString = (queryData, replace = true) => {
        const encodedQueryData = encodeQueryData(queryData)

        if (replace) {
            window.history.replaceState('', '', `?${encodedQueryData}`)
        } else {
            navigate(`${state.pathname}?${encodedQueryData}`)
            // window.history.pushState('', '', `${url}?${encodedQueryData}`)
        }
    }

    const getCurrentClass = (currentClassName = state.currentClassName, allClasses = state.allClasses) =>
        find(allClasses, (classObj) => classObj.id === currentClassName)

    const findStartNode = (currentClass) => find(currentClass.skillTree, ({ start }) => start)

    switch (action.type) {
        case 'set':
            return { ...state, ...action.payload }
        case 'init': {
            let currentClass = cloneDeep(getCurrentClass())
            let startingNode = findStartNode(currentClass)

            let obtainedSkills = [startingNode.skillId]

            if (state.initialBuildCode !== 'undefined') {
                const buildCodeIsValid = validateBuildCode(state.initialBuildCode)

                if (buildCodeIsValid) {
                    obtainedSkills = buildCodeToSkills(state.initialBuildCode)

                    currentClass.skillTree.forEach((skillNode) => {
                        if (obtainedSkills.includes(skillNode.skillId)) {
                            skillNode.state = STATE_OBTAINED

                            for (let unlockSkillNodeId of skillNode.unlocks) {
                                const childNode = currentClass.skillTree[unlockSkillNodeId]
                                childNode.state = obtainedSkills.includes(unlockSkillNodeId)
                                    ? STATE_OBTAINED
                                    : STATE_AVAILABLE
                                childNode.parents = [...(childNode.parents || []), skillNode.skillId]
                            }
                        }
                    })

                    const hasOrphanNodes = currentClass.skillTree.some((s) => s.state == STATE_OBTAINED && !s.start && !s.parents)
                    if (hasOrphanNodes) {
                        // Reset
                        updateQueryString({ class: state.currentClassName }) // wipes 'build'
                        currentClass = cloneDeep(getCurrentClass())
                        startingNode = findStartNode(currentClass)

                        return {
                            ...state,
                            currentClass,
                            currentSkillId: startingNode.skillId,
                            obtainedSkills: [startingNode.skillId],
                            isLoading: false,
                            initialBuildCodeValid: false
                        }
                    } else {
                        updateQueryString({ class: state.currentClassName, build: state.initialBuildCode })
                    }
                } else {
                    updateQueryString({ class: state.currentClassName }) // wipes 'build'
                    state.initialBuildCodeValid = false
                }
            }

            return {
                ...state,
                currentClass,
                currentSkillId: startingNode.skillId,
                obtainedSkills: obtainedSkills,
                isLoading: false
            }
        }
        case 'reset': {
            const currentClass = cloneDeep(getCurrentClass())
            const startingNode = findStartNode(currentClass)

            updateQueryString({ class: state.currentClassName }) // wipes 'build'

            return {
                ...state,
                currentClass,
                currentSkillId: startingNode.skillId,
                obtainedSkills: [startingNode.skillId],
                isLoading: false,
                initialBuildCodeValid: true
            }
        }
        case 'obtainSkill': {
            const { skillNodeId } = action.payload
            const { skillTree } = cloneDeep({ ...state.currentClass })
            const skillNode = skillTree[skillNodeId]

            skillNode.state = STATE_OBTAINED

            for (let unlockSkillNodeId of skillNode.unlocks) {
                const childNode = skillTree[unlockSkillNodeId]

                childNode.parents = [...(childNode.parents || []), skillNodeId]

                if (childNode.state === STATE_LOCKED) {
                    childNode.state = STATE_AVAILABLE
                }
            }

            const newObtainedSkills = [skillNode.skillId, ...state.obtainedSkills]

            updateQueryString({ class: state.currentClassName, build: skillsToBuildCode(newObtainedSkills) })
            !!window && !!window.appInsights &&
                window.appInsights.trackEvent({
                    name: 'Skills: Obtain',
                    properties: {
                        class: state.currentClassName,
                        skill: state.currentClass.skills[skillNode.skillId].name
                    }
                })

            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    skillTree
                },
                obtainedSkills: newObtainedSkills
            }
        }
        case 'deleteSkill': {
            const { currentClass, obtainedSkills } = state
            const { skillNodeId } = action.payload
            const { skillTree } = cloneDeep({ ...state.currentClass })
            const skillNode = skillTree[skillNodeId]
            const hasChildUnlocked = pipe(
                filter((id) => obtainedSkills.includes(id) && count(skillTree[id].parents) === 1),
                count
            )(skillNode.unlocks)

            if (skillNode.start || hasChildUnlocked) return state

            skillNode.state = STATE_AVAILABLE

            for (let unlockSkillNodeId of skillNode.unlocks) {
                const childNode = skillTree[unlockSkillNodeId]

                if (childNode.state === STATE_AVAILABLE && count(childNode.parents) === 1) {
                    skillTree[unlockSkillNodeId].state = STATE_LOCKED
                }

                childNode.parents = filter((id) => id !== skillNodeId, childNode.parents)
            }

            const newObtainedSkills = obtainedSkills.filter((id) => id !== skillNodeId)

            updateQueryString({ class: state.currentClassName, build: skillsToBuildCode(newObtainedSkills) })

            return {
                ...state,
                currentClass: {
                    ...currentClass,
                    skillTree
                },
                obtainedSkills: newObtainedSkills
            }
        }
        default:
            throw new Error(`Invalid action type passed to reducer: ${action.type}`)
    }
}

const INITIAL_STATE = {
    isLoading: true,
    currentClassName: null,
    initialBuildCode: null,
    initialBuildCodeValid: true,
    currentSkillId: null,
    currentClass: {},
    obtainedSkills: []
}

const initializeState = (images) => (state) => ({
    ...state,
    icons: reduce(
        (imageMap, image) => ({
            ...imageMap,
            [image.name]: image
        }),
        {},
        images
    )
})

const initI18n = (pageContext, enLocData, currentLocData) => {
    const lang = getLocaleFromWPMLLocale(pageContext.locale)

    each(enLocData.LocalizationEntries, (enEntry) => {
        i18n.addResource('en-us', 'title', enEntry.Text, enEntry.Text)
        i18n.addResource('en-us', 'title', enEntry.Guid, enEntry.Text)

        if (!!currentLocData && lang !== 'en') {
            const currentLocEntry = find(currentLocData.LocalizationEntries, (currEntry) => currEntry.Guid == enEntry.Guid)
            i18n.addResource(pageContext.locale, 'title', enEntry.Text, currentLocEntry.Text)
            i18n.addResource(pageContext.locale, 'title', enEntry.Guid, currentLocEntry.Text)
        }
    })
}

const SkillsPage = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { querystring, location } = useLocation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const sortedClasses = sortBy(props.data.skills.nodes, ['order'])
    const classIds = sortedClasses.map(({ id }) => id)
    const [state, dispatch] = useReducer(
        reducer,
        {
            ...INITIAL_STATE,
            allClasses: sortedClasses,
            currentClassName: getCurrentClassName(querystring.class, classIds),
            initialBuildCode: String(querystring.build),
            pathname: location.pathname
        },
        initializeState(props.data.icons.nodes)
    )

    const manipulateSkill = (type) => (skillNodeId) => {
        dispatch({ type, payload: { skillNodeId } })
    }
    const obtainSkill = manipulateSkill('obtainSkill')
    const deleteSkill = manipulateSkill('deleteSkill')

    const displaySkill = (skillId) => {
        dispatch({ type: 'set', payload: { currentSkillId: skillId } })
    }
    const resetSkillTree = () => {
        dispatch({ type: 'reset' })
    }
    const handleClassChange = (_, currentClassName) => {
        const encodedQueryData = encodeQueryData({ class: currentClassName })
        navigate(`${state.pathname}?${encodedQueryData}`)
    }

    useEffect(() => {
        initI18n(props.pageContext, props.data.enLoc, props.data.currLoc)
        dispatch({ type: 'init' })
    }, [])

    useEffect(() => {
        dispatch({
            type: 'set',
            payload: {
                currentClassName: getCurrentClassName(querystring.class, classIds),
                initialBuildCode: String(querystring.build)
            }
        })
        dispatch({ type: 'init' })
    }, [querystring.class])

    return (
        <LayoutContent {...props} setBackgroundImage gutters='none'>
            <SkillsHelmet data={props.data} />

            <ClassContext.Provider
                value={{
                    ...state,
                    resetSkillTree,
                    obtainSkill,
                    deleteSkill,
                    displaySkill
                }}
            >
                {state.isLoading ? (
                    <SkeletonUI />
                ) : (
                    <>
                        <svg height='0' width='0' viewBox='0 0 0 0'>
                            <defs>
                                <filter id='shadowObtained'>
                                    <feDropShadow dx='0.0' dy='0.0' stdDeviation='2' floodColor={theme.palette.border.white} floodOpacity="0.8" />
                                </filter>
                                <filter id='shadowHover'>
                                    <feDropShadow dx='0.0' dy='0.0' stdDeviation='2.5' floodColor={theme.palette.background.default} floodOpacity="0.8" />
                                </filter>
                            </defs>
                        </svg>
                        <Hero hasNegativeMargins={false} className={classes.hero} useContentHeight backgroundImage={isMobile ? heroImgMobile : heroImgDesktop}>
                            <HeroContent classes={{ root: classes.heroContent, container: classes.heroContentContainer }}>
                                <h1 className={classnames('h1', classes.heroHeader)}>{t('Skill Planner')}</h1>
                                <div className={classes.heroContentDescription}>
                                    <p>{t('slug:skill-hero-description')}</p>
                                    <Link to={`/${get(props, 'pageContext.locale')}/game-guide/skills/`}>{t('Learn more about skills')}</Link>
                                    <p>{t('or')}</p>
                                    <Link to={`/${get(props, 'pageContext.locale')}/game-guide/classes/`}>{t('view Class skills list')}.</Link>
                                </div>
                            </HeroContent>
                        </Hero>
                        <Section className={classes.section} gutters='condensed'>
                            <Tabs value={state.currentClassName} indicatorType='pill' onChange={handleClassChange}>
                                {map(
                                    ({ id, displayName }) => (
                                        <Tab key={`category-${id}`} label={t(displayName)} value={id} className={classes.tab}/>
                                    ),
                                    state.allClasses
                                )}
                            </Tabs>

                            <SkillBuilder />
                        </Section>
                    </>
                )}
            </ClassContext.Provider>
        </LayoutContent>
    )
}

export const pageQuery = graphql`
    query($cmsLocale: String) {
        enLoc: skillBuilderLocJson(Locale: { eq: "en" }) {
            LocalizationEntries {
                Guid
                Text
            }
        }
        currLoc: skillBuilderLocJson(Locale: { eq: $cmsLocale }) {
            LocalizationEntries {
                Guid
                Text
            }
        }
        siteData: site {
            siteMetadata {
                title
                siteUrl
            }
        }
        socialImage: file(relativePath: { regex: "/social/skills/" }) {
            publicURL
        }
        skills: allSkillBuilderClassJson {
            nodes {
                id
                order
                displayName
                quadrants {
                    name
                    displayName
                    tooltip
                }
                skillTreeStructure {
                    numRows
                    numColumns
                }
                skillTree {
                    skillId
                    state
                    unlocks
                    start
                    coords {
                        x
                        y
                    }
                }
                skills {
                    id
                    description
                    name
                    displayName
                    icon
                    power
                    maxPower
                    type
                    cooldown
                    actionPointsCost
                    values
                    previousSkill
                }
            }
        }
        icons: allFile(filter: { relativePath: { regex: "images/skills/icons/" } }) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(height: 64, placeholder: NONE, layout: FIXED)
                }
            }
        }
    }
`

export default SkillsPage
