import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'

import { formatSkillTranslation } from '../../utilities/helpers'
import SkillPopper from './skillPopper'
import { TYPE_ACTIVE } from './skillKeys'
import actionPointIcon from '../../assets/images/skills/ActionPoint.png'
import cooldownIcon from '../../assets/images/skills/Turn.png'

const MOBILE_ICON_SIZE = 22
const DESKTOP_ICON_SIZE = 30

const useStyles = makeStyles(({ palette, breakpoints, accessibility }) => ({
  root: {
    [breakpoints.up('md')]: { width: '500px' }
  },
  skillHeader: {
    display: 'flex',
    borderBottom: `1px solid ${palette.common.white}`,
    paddingBottom: '10px'
  },
  skillContent: {
    paddingTop: '10px'
  },
  skillMeta: {
    display: 'flex',
    alignItems: 'flex-end',
    [breakpoints.up('md')]: {
      alignItems: 'center'
    }
  },
  skillMetaIcon: {
    marginRight: 10,
    width: MOBILE_ICON_SIZE,
    height: MOBILE_ICON_SIZE,
    [breakpoints.up('md')]: {
      width: DESKTOP_ICON_SIZE,
      height: DESKTOP_ICON_SIZE
    },
    ...accessibility.highContrast.blackOnWhite
  },
  cooldownIcon: {
    marginLeft: '20px'
  }
}))

const SkillDetails = ({ skill, children, open, isFlipped, onClose, onOpen }) => {
  const { t } = useTranslation()
  const _classes = useStyles()
  const PopperContent = (
    <div className={_classes.root}>
      <div className={_classes.skillHeader}>
        <div style={{ flex: 1 }}>
          <Typography className='h4'>{t(skill.displayName)}</Typography>
          <Typography>
            {skill.type === TYPE_ACTIVE ? t('Active Skill') : t('Passive Skill')}
          </Typography>
        </div>
        {skill.type === TYPE_ACTIVE &&
          <div className={_classes.skillMeta}>
            <img src={actionPointIcon} alt={t('Action Point')} className={_classes.skillMetaIcon} />
            <Typography className='h4'>
              {skill.actionPointsCost}
            </Typography>
            <img
              src={cooldownIcon}
              alt={t('Cooldown')}
              className={classnames(_classes.skillMetaIcon, _classes.cooldownIcon)}
            />
            <Typography className='h4'>
              {skill.cooldown}
            </Typography>
          </div>
        }
      </div>
      <div className={_classes.skillContent}>
        <Typography>{formatSkillTranslation(t(skill.description), ...skill.values)}</Typography>
      </div>
    </div>
  )

  return (
    <SkillPopper
      open={open}
      isFlipped={isFlipped}
      onClose={onClose}
      onOpen={onOpen}
      title={PopperContent}
    >
      {children}
    </SkillPopper>
  )
}

export default SkillDetails
