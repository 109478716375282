import React, { useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import get from 'tcweb-material-components/core/poly/get'

import ClassContext from '../context/classContext'

const useClasses = makeStyles(() => ({
    root: ({ size }) => ({
        width: size,
        height: size
    })
}))

const SkillIcon = ({ className, icon, size }) => {
    const _classes = useClasses({ size })
    const { icons } = useContext(ClassContext)

    if (!icon) return null
    if (!icons[icon]) return null

    return <GatsbyImage
                className={classnames(_classes.root, className)}
                image={{
                    ...get(icons[icon], 'childImageSharp.gatsbyImageData'),
                    width: get(size, get(icons[icon], 'childImageSharp.gatsbyImageData.width')),
                    height: get(size, get(icons[icon], 'childImageSharp.gatsbyImageData.height')),
                }}
                role='presentation'
                alt=''
            />
}

export default SkillIcon