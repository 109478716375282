import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from 'tcweb-material-components/core/button'

import SkillPopper from './skillPopper'

const useClasses = makeStyles(({ accessibility: { highContrast } }) => ({
    root: { textAlign: ({ index }) => (index % 2 === 0 ? 'left' : 'right') },
    button: {
        width: 'auto',
        lineHeight: 1.1
    },
    label: {
        textDecoration: 'underline',
        textTransform: 'capitalize',

        [highContrast.mediaQuery('active')]: {
            '&:hover, &:focus': {
                color: 'Highlight'
            }
        }
    },
    mobileContainer: { paddingRight: 20 }
}))

const FLIP_THRESHOLD = 1

const SkillQuadrant = ({ quadrant, index }) => {
    const { t } = useTranslation()
    const _classes = useClasses({ index })
    const [detailsVisible, setDetailsVisible] = useState(false)
    const isFlipped = useMemo(() => index > FLIP_THRESHOLD, [])

    const onDetailsShow = useCallback(() => setDetailsVisible(true))
    const onDetailsHide = useCallback(() => setDetailsVisible(false))

    return (
        <div className={_classes.root}>
            <SkillPopper
                open={detailsVisible}
                isFlipped={isFlipped}
                onClose={onDetailsHide}
                onOpen={onDetailsShow}
                mobileContainerClassName={_classes.mobileContainer}
                title={<Typography>{t(quadrant.tooltip)}</Typography>}
            >
                <Button
                    classes={{ root: _classes.button, label: _classes.label }}
                    onClick={onDetailsShow}
                    aria-expanded={String(detailsVisible)}
                    aria-haspopup={'true'}
                >
                    {t(quadrant.displayName)}
                </Button>
            </SkillPopper>
        </div>
    )
}

export default SkillQuadrant
